import React from 'react'
import { css } from 'styled-components'

export const WidgetNoDataContainer: React.FC = ({ children }) => (
  <div
    css={css`
      height: 200px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .empty-row-content {
        flex: 0 0;
      }
    `}>
    <div className="empty-row-content">{children}</div>
  </div>
)
