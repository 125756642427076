import React from 'react'
import { observer } from 'mobx-react-lite'
import { Trans, Plural } from '@lingui/macro'
import { TableSelectRowsStore } from './stores/TableSelectRowsStore'

export const TableNotice = observer(
  ({
    selecting,
    isFiltered = undefined,
  }: {
    selecting: TableSelectRowsStore
    isFiltered?: boolean
  }) => {
    // According to this: https://lingui.js.org/tutorials/react.html#variables-and-components you should put Trans inside Plural.
    // When I put it that way, nothing is returned... I also got issue with this: https://github.com/lingui/js-lingui/issues/437, so maybe it's related
    // But anyway, it's working this way, so probably no big deal.

    if (selecting.allRowsSelected) {
      return (
        <div>
          <Plural
            value={selecting.selected.length}
            one={
              <span>
                Všetky{' '}
                {isFiltered && selecting.allIds.length === selecting.selected.length
                  ? 'vyfiltrované'
                  : ''}{' '}
                položky sú označené.
              </span>
            }
            few={
              <span>
                Všetky <strong>#</strong>{' '}
                {isFiltered && selecting.allIds.length === selecting.selected.length
                  ? 'vyfiltrované'
                  : ''}{' '}
                položky sú označené.
              </span>
            }
            other={
              <span>
                Všetkých <strong>#</strong>{' '}
                {isFiltered && selecting.allIds.length === selecting.selected.length
                  ? 'vyfiltrovaných'
                  : ''}{' '}
                položiek je označených.
              </span>
            }
          />{' '}
          <button className="link link-white" onClick={() => selecting.handleAllRowsSelect(false)}>
            <Trans>Zrušiť označenie</Trans>
          </button>
        </div>
      )
    }
    if (selecting.selected.length > 0) {
      return (
        <div>
          <Plural
            value={selecting.selected.length}
            one={
              <span>
                Je označená <strong>#</strong>{' '}
                {isFiltered && selecting.allIds.length === selecting.selected.length
                  ? 'vyfiltrovaná'
                  : ''}{' '}
                položka.
              </span>
            }
            few={
              <span>
                Sú označené <strong>#</strong>{' '}
                {isFiltered && selecting.allIds.length === selecting.selected.length
                  ? 'vyfiltrované'
                  : ''}{' '}
                položky.
              </span>
            }
            other={
              <span>
                Je označených <strong>#</strong>{' '}
                {isFiltered && selecting.allIds.length === selecting.selected.length
                  ? 'vyfiltrovaných'
                  : ''}{' '}
                položiek.
              </span>
            }
          />{' '}
          <button className="link " onClick={() => selecting.handleAllRowsSelect(true)}>
            <Plural
              value={selecting.allIds.length}
              one={<span>Označiť všetky {isFiltered ? 'vyfiltrované' : ''} položky</span>}
              few={<span>Označiť všetky # {isFiltered ? 'vyfiltrované' : ''} položky</span>}
              other={<span>Označiť všetkých # {isFiltered ? 'vyfiltrovaných' : ''} položiek</span>}
            />
          </button>
          {' | '}
          <button className="link link-white" onClick={() => selecting.handleAllRowsSelect(false)}>
            <Trans>Zrušiť označenie</Trans>
          </button>
        </div>
      )
    }

    return null
  }
)
