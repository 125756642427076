import { observer, useLocalStore } from 'mobx-react-lite'
import React from 'react'
import { useRouter } from 'next/router'
import { Bar, BarItem } from '../common/ui-kit/bar'
import { ClientCtx, clientStore } from './ClientStore'

import Error from '../../pages/_error'
import { Loader } from '../common/ui-kit/loader'
import { SCOPES } from '../config/constants'
import { appStore } from '../common/AppStore'
import { storeMergedProducts } from '../common/StoreProducts'
import { useDidMount } from '../common/utils/hooks/useDidMount'

/**
 * Fetch employee based on current user for scope `Holder` and sets it's value on context `EmployeeCtx`
 */
export const FetchClient = observer((props: { children: JSX.Element }) => {
  const router = useRouter()

  const state = useLocalStore(() => ({
    state: 'initial' as 'initial' | 'loaded' | 'error',
    async fetchProducts() {
      try {
        await storeMergedProducts.fetch(SCOPES.Client)
        this.state = 'loaded'
      } catch (err) {
        this.state = 'error'
      }
    },
  }))

  useDidMount(() => {
    const clientIdFromLocalStorage = clientStore.getClientIdFromLocalStorage()
    const clientIdFromRoute = router?.query?.activeClientId as string

    if (clientIdFromRoute && appStore.hasIdInAccesses(clientIdFromRoute)) {
      clientStore.fetchClient(clientIdFromRoute!).then(state.fetchProducts)
    } else if (clientIdFromLocalStorage && appStore.hasIdInAccesses(clientIdFromLocalStorage)) {
      clientStore.fetchClient(clientIdFromLocalStorage).then(state.fetchProducts)
    } else {
      const clientId = appStore.getIdInScope(SCOPES.Client)

      if (clientId) {
        clientStore.saveClientIdToLocalStorage(clientId)
        clientStore.fetchClient(clientId).then(state.fetchProducts)
      }
    }
  })

  if (!clientStore.client || state.state !== 'loaded') {
    return (
      <Bar align="middle">
        <BarItem
          isFilling
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '110px',
            paddingBottom: '110px',
          }}>
          <Loader size="large" />
        </BarItem>
      </Bar>
    )
  }

  if (process.browser && clientStore) {
    const forbiddenUrls = clientStore.getForbiddenUrls()
    const currentUrl = router.pathname
    for (const forbiddenUrl of forbiddenUrls) {
      if (currentUrl.startsWith(forbiddenUrl)) {
        return <Error />
      }
    }
  }

  appStore.currentRole = SCOPES.Client
  appStore.saveLastEntityIdToLocalStorage(clientStore.client.id)

  return <ClientCtx.Provider value={clientStore.client}>{props.children}</ClientCtx.Provider>
})
