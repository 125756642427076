import { observable, computed } from 'mobx'
import { xor, uniq } from 'lodash-es'

export class TableSelectRowsStore {
  /**
   * array of all ids accross table pages
   */
  @observable allIds: string[] = []

  /**
   * array of ids on current page
   */
  @observable pageIds: string[] = []

  /**
   * selected ids
   */
  @observable selected: string[] = []

  /**
   * toggle id from `selected` array
   */
  handleRowSelect = (id: string) => {
    this.selected = xor(this.selected, [id])
  }

  /**
   * fill/reset `selected` based on page checkbox value
   */
  handleAllRowsSelect = (checked: boolean) => {
    this.selected = checked ? [...this.allIds] : []
  }

  handlePageRowsSelect = (checked: boolean) => {
    this.selected = checked
      ? uniq([...this.selected, ...this.pageIds])
      : this.selected.filter(id => !this.pageIds.includes(id))
  }

  handleRowsReset = () => {
    this.selected = []
    // this.pageIds = [] // resetting this breaks select-all-page-ids checkbox on filter change, AND it doesn't seem to be necessary
    // this.allIds = []
  }

  @computed get allRowsSelected() {
    return this.allIds.length && this.allIds.every(id => this.selected.includes(id))
  }

  @computed get allPageRowsSelected() {
    return this.pageIds.length && this.pageIds.every(id => this.selected.includes(id))
  }
}
