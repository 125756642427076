import React from 'react'
import { css } from 'styled-components'

export const ShowinTheMiddle: React.FC = ({ children }) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50vh;
      `}>
      {children}
    </div>
  )
}
