import React, { useEffect } from 'react'
import { Trans } from '@lingui/react'
import { ShowinTheMiddle } from '../src/common/utils/ShowInTheMiddle'
import { appStore } from '../src/common/AppStore'

function Error() {
  useEffect(() => {
    appStore.is404 = true
    return () => {
      appStore.is404 = false
    }
  }, [])

  return (
    <ShowinTheMiddle>
      <div>
        <h3 className="mb-xlarge">
          <Trans>Ľutujeme, stránku sa nepodarilo nájsť</Trans>
        </h3>

        <p>
          <Trans>
            Stránka, ktorú hľadáte, už nie je k dispozícii alebo bola presunutá. Skontrolujte, či
            ste zadali správnu URL adresu.
          </Trans>
        </p>
      </div>
    </ShowinTheMiddle>
  )
}

export default Error
